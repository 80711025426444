import React, { Component } from "react";
import { Col, Container } from "react-bootstrap";
import Animation, { fadeInLeft } from "../Util/Animation";
import rmp from "../assets/images/rmp.jpg"

class Roadmap extends Component {
  constructor(props) {
    super(props);
    this.state = {  
    };
  }
  render() {
    return (
      <div class="pb-90 sell-collect-section new">
        <Container>
          <br />
          <br />
          <br />
          <br />
          <Animation
            className="row sell"
            variants={fadeInLeft}
            delay={400} 
          >
            <Col md={12}>
              <img alt="rmp" src={rmp} />
            </Col>
          </Animation>
        </Container>
      </div>
    );
  }
}
export default Roadmap;
