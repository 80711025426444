import React, { Component } from "react";

import l1 from "../assets/images/l1.svg";
import { Link } from "react-router-dom";

class MobileMenu extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        navstyle:{
            display:"none"
        }
      };
    }
    render() {
        return <div className="mobile-menu-area d-sm-block d-md-block d-lg-none ">
            <div className="mobile-menu mean-container">
                <div className="mean-bar">
                    <Link to={'/'} className="logo_img" title="nftpro">
                        <img src={l1} alt="logo" />
                    </Link>

                    <div className="header-button hd">
                        <Link to={'/buy'}>Buy Now</Link>
                    </div>
                </div>
                <nav className="nftpro_menu" style={this.state.navstyle}>
                    <ul className="nav_scroll">
                        <li > <Link to={'/'}><span>Home</span> </Link> </li>
                        <li > <Link to={'/whitepaper'}><span>Company WhitePaper</span> </Link> </li>
                        <li > <Link to={'/roadmap'}><span>Our Road map</span> </Link> </li>
                    </ul>
                </nav>
            </div>
        </div>
    }
}

export default MobileMenu;