import { v4 as uuidv4 } from "uuid";
import { request } from "../Util/request";

class Order {
    static async generateOrderID(){  
        const id = uuidv4();
        const orderid =await request.get('/api/').then((response)=>{                
            if(response.status===200){
                return response.data?.orderid??id;
            }else{                  
                return id;
            }
        }).catch(()=>{ 
            return id;
        }); 
        return orderid;
    }
    static async createOrder(data){

        const order =await request.post('/api/',{
            method:'order',
            data:data
        }).then((response)=>{      
            let result = {}          
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return order;
    }
    static async updateOrder(data,orderid){

        const order =await request.post('/api/',{
            method:'update-order',
            data:data,
            id:orderid
        }).then((response)=>{      
            let result = {}          
            if(response.status>=200&&response.status<=204){
                result= response.data?.data??{};
            }
            return result;
        }).catch(()=>{ 
            return {};
        }); 
        return order;
    }
}
export default Order;