import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import StickyHeader from './StickyHeader';
import MobileMenu from './MobileMenu';
import Footer from './Footer';

class Layout extends Component{
    render(){
        return ( 
            <>
                <StickyHeader/>
                <MobileMenu/>
                <Outlet /> 
                <Footer/>
            </> 
        );
    }
}
  

export default Layout;