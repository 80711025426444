import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'animate.css/animate.min.css';

import Layout from './Layout';
import Home from './templates/Home';
import Buy from './templates/Buy';
import Whitepaper from './templates/Whitepaper';
import Roadmap from './templates/Roadmap';
import ADXCoin from './service/ADXCoin'; 
import Terms from './templates/Terms';
import Cookie from './templates/Cookie';
import Privacy from './templates/Privacy';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }
  componentDidMount() {
    if(!ADXCoin.isLoaded){
      ADXCoin.ConectNetworks()
    }
    ADXCoin.ConnectProxyAccount()
  }
  componentWillUnmount() {
    if(ADXCoin.isLoaded){
      ADXCoin.DisConectNetworks()
    }
  }
  render() {
    return (
      <BrowserRouter  >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/buy' element={<Buy />} />
            <Route path='/whitepaper' element={<Whitepaper />} />
            <Route path='/roadmap' element={<Roadmap />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/cookie' element={<Cookie />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}



export default App;
