import React,{ Component } from "react";

import l1 from "../assets/images/l1.svg";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import Animation, { fadeInDown, fadeInLeft, fadeInUp,fadeInRight } from "../Util/Animation";

class Footer extends Component{
    render(){
        return  (<div className="footer-middle pt-80 pb-3">
            <Container>
                <Row>
                    <Col lg={3} md={6} sm={12}>
                        <Animation className="widgets-company-info pt-3" variants={fadeInLeft}  delay={400} > 
                            <div className="nftpro-logo">
                                <Link to={'/'} className="logo_img" title="nftpro">
                                    <img src={l1} alt="logo" />
                                </Link>
                            </div>
                            <div className="company-info-desc">
                                <p>ADROX the Frontier of Future Finance, where the future of cryptocurrency meets innovative technology.</p>
                            </div>
                            <div className="company_icon">
                                <div className="company-icon-title">
                                    <h3>Follow Us</h3>
                                </div>
                                <a className="facebook" href="https://www.facebook.com/profile.php?id=61559936651774" target="blank"><i ><FontAwesomeIcon icon={faFacebook} /></i></a>
                                <a className="twitter" href="https://x.com/adroxorg?s=21" target="blank"><i><FontAwesomeIcon icon={faTwitter} /></i></a>
                                <a className="Instagram" href="https://www.instagram.com/adroxmarket/" target="blank"><i><FontAwesomeIcon icon={faInstagram} /></i></a>        
                            </div>                        
                        </Animation>
                    </Col>
                    <Col lg={9} md={6} sm={12}>
                        <Row >
                            <Col lg={3} md={6} sm={12}>
                                <Animation className="widget-nav-menu"  variants={fadeInUp} delay={500}>
                                    <h4 className="widget-title">Terms</h4>
                                    <div className="menu-quick-link-content">
                                        <ul className="menu">        
                                            <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                                            <li><Link to={'/terms'}>Terms & Conditions</Link></li>
                                            <li><Link to={'/cookie'}>Cookie Policy</Link></li>
                                        </ul>
                                    </div>
                                </Animation>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <Animation className="widget-nav-menu upper"  variants={fadeInDown} delay={600}>
                                    <h4 className="widget-title">Marketplace</h4>
                                    <div className="menu-quick-link-content">
                                        <div className="wallet-button">
                                            <Link to={'/buy'}>Buy ADX Token </Link>
                                        </div>
                                    </div>
                                </Animation>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <Animation className="widget-nav-menu upper1" variants={fadeInUp} delay={700}>
                                    <h4 className="widget-title">Support</h4>
                                    <div className="menu-quick-link-content">
                                        <ul className="menu">
                                            <li><a href="mailto:hello@adroxmarket.com" >Hello@adroxmarket.com</a></li>
                                            <li><a href="mailto:help@adroxmarket.com">Help@adroxmarket.com</a></li>
        
                                        </ul>
                                    </div>
                                </Animation>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <Animation className="widget-nav-menu upper2 " variants={fadeInRight} delay={800}>
                                    <h4 className="widget-title">ADX Quick Links</h4>
                                    <div className="menu-quick-link-content">
                                        <ul className="menu">
                                            <li > <Link to={'/roadmap'}>Roadmap</Link> </li>
                                            <li > <Link to={'/whitepaper'}>WhitePaper</Link> </li>
                                            <li > <Link to={'/'}>Home</Link> </li>
                                            <li > <Link to={'/buy'}>Buy Now </Link>  </li>
                                        </ul>
                                    </div>
                                </Animation>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="footer-bottom mt-80">
                    <Col lg={12} sm={12} >
                        <Animation className="footer-bottom-content pt-3" variants={fadeInLeft}  delay={900} > 
                            <div className="footer-bottom-content-copy">
                                <p>@ 2024 All Right Reserved  ADROX </p>
                            </div>
                        </Animation>
                    </Col>
                </Row>
            </Container>
        </div>);
    }
}


export default Footer;