import axios from 'axios'; 
import { API_ENDPOINT, SOLANA_ENDPOINT } from "./constant";
import { BINANCE_ENDPOINT } from './constant';

export const request = axios.create({
    baseURL: API_ENDPOINT,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    headers:{  
        'Content-Type': 'application/json',
        'Accept': 'application/json',  
    }
})

export const binance = axios.create({
    baseURL: BINANCE_ENDPOINT,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    headers:{  
        'Content-Type': 'application/json',
        'Accept': 'application/json',  
    }
})

export const solana = axios.create({
    baseURL: SOLANA_ENDPOINT,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    headers:{  
        'Content-Type': 'application/json',
        'Accept': 'application/json',  
    }
})