import ReconnectingWebSocket from "reconnecting-websocket";
import { BINANCE_W3, BNBUSDT_SOCKET, SOLUSDT_SOCKET } from "../Util/constant";
import Web3 from "web3";
import { Keypair } from "@solana/web3.js";

class ADXCoin{
    static _is_loaded=false; 
    static _coin_rate={
        SOL: null,
        BNB: null,
        USDT: 1,
    }
    static _price = 0.05;
    static _solusdt = null;
    static _bnbusdt = null;
    static _usdtusdt = null;
    static _on_price_change = null;
    static get isLoaded() {
        return this._is_loaded;
    }
    static set isLoaded(isloaded) {
        this._is_loaded = isloaded;
    } 
    static get ADXPriceUpdate(){
        return this._on_price_change;
    }
    static set ADXPriceUpdate(fn){
        this._on_price_change=fn;
    }
    
    static get Wallet(){
        return JSON.parse(localStorage.getItem("_adx_wallet"))||{};
    }
    static set Wallet(wallet){
        localStorage.setItem("_adx_wallet",JSON.stringify(wallet||{}))
    }
    static get Account(){
        return JSON.parse(localStorage.getItem("_adx_wallet_account"))||{};
    }
    static set Account(account){
        localStorage.setItem("_adx_wallet_account",JSON.stringify(account||{}))
    }
    static get ADXPriceInUSD(){
        return this._price;
    }
    static set ADXPriceInUSD(price){
        this._price=(price|| 0.05);
    }    
    static get ADXPrices(){
        return this._coin_rate;
    }
    static set ADXPrices(prices){
        this._coin_rate=prices;
    }
    static async SOLPriceChange(event){
        const data = JSON.parse(event.data);
        if(data.p){
            ADXCoin.ADXPrices={
                ...ADXCoin.ADXPrices,
                SOL:parseFloat(data.p)
            };
            if(ADXCoin.ADXPriceUpdate){
                ADXCoin.ADXPriceUpdate()
            }
        }
    }
    static async BNBPriceChange(event){
        const data = JSON.parse(event.data); 
        if(data.p){            
            ADXCoin.ADXPrices={
                ...ADXCoin.ADXPrices,
                BNB:parseFloat(data.p)
            };
            if(ADXCoin.ADXPriceUpdate){
                ADXCoin.ADXPriceUpdate()
            }
        }
    }
    static async USDTPriceChange(event){
        const data = JSON.parse(event.data); 
        if(data.p){   
            ADXCoin.ADXPrices={
                ...ADXCoin.ADXPrices,
                USDT:parseFloat(data.p)
            };
            if(ADXCoin.ADXPriceUpdate){
                ADXCoin.ADXPriceUpdate()
            }
        }
    }
    static ConectNetworks(){
        this._solusdt = new ReconnectingWebSocket(SOLUSDT_SOCKET);
        this._bnbusdt = new ReconnectingWebSocket(BNBUSDT_SOCKET);
        // this._usdtusdt = new ReconnectingWebSocket(USDTUSDT_SOCKET);
        this._solusdt.onmessage=this.SOLPriceChange;
        this._bnbusdt.onmessage=this.BNBPriceChange;
        // this._usdtusdt.onmessage=this.USDTPriceChange;
        this._is_loaded=true;
    }
    static DisConectNetworks(){
        this._solusdt.close()
        this._bnbusdt.close()
        // this._usdtusdt.close()
        this._is_loaded=false;
    }
    static ConnectProxyAccount(){
        this.ConnectProxyAccountUSDT();
        this.ConnectProxyAccountBNB();
        this.ConnectProxyAccountSOL();
    }
    static ConnectProxyAccountUSDT(){
        if(!ADXCoin.Account?.USDT){
            const web3 = new Web3(BINANCE_W3);
            const account = web3.eth.accounts.create();
            ADXCoin.Account={
                ...ADXCoin.Account,
                USDT:{
                    address:account.address,
                    privateKey:account.privateKey,
                }
            }
        }
    }
    static ConnectProxyAccountBNB(){
        if(!ADXCoin.Account?.BNB){
            const web3 = new Web3(BINANCE_W3);
            const account = web3.eth.accounts.create();
            ADXCoin.Account={
                ...ADXCoin.Account,
                BNB:{
                    address:account.address,
                    privateKey:account.privateKey,
                }
            }
        }
    }
    static ConnectProxyAccountSOL(){
        if(!ADXCoin.Account?.SOL){
            const keypair = Keypair.generate(); 
            ADXCoin.Account={
                ...ADXCoin.Account,
                SOL:{
                    address:keypair.publicKey.toBase58(),
                    privateKey:Buffer.from(keypair.secretKey).toString('hex'),
                }
            }
        }
    }
    
}


export default ADXCoin;