import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import blogd from "../assets/images/resource/blog-d.png"

class Privacy extends Component {

    render() {
        return (<>
            <div class="breadcome-area d-flex align-items-center">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-12">
                            <div class="breadcome-title">
                                <h2>Privacy Policy</h2>
                                <p><Link to={'/'} >Home <span>Privacy Policy</span></Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-section pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-details-box details">
                                        <div class="blog-box-inner">
                                            <div class="dreamit-blog-thumb">
                                                {/* <img src={blogd} alt=""/> */}
                                            </div>
                                            <div class="popular-blog-content">

                                                <div class="blog-title">
                                                    <h2><Link>Privacy Policy for adroxmarket.com  </Link></h2>
                                                </div>
                                                <div class="blog-text white">

                                                    <p class="details-text">	1. Introduction  <br />

                                                        Welcome to Adrox Market. We appreciate your presence on our platform and are committed to safeguarding and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your information. By using Adrox Market, you consent to the practices described in this policy.

                                                        <br /> <br />  2. Information We Collect<br />

                                                        We collect and process various types of information, including:

                                                        - Personal data that you voluntarily provide to us, such as your name, email address, and transaction details.
                                                        - Automatically collected information through cookies and similar technologies, including IP addresses, browser types, and browsing behavior on our site.

                                                        <br /> <br />  3. Use of Information  <br />

                                                        We use your information for the following purposes:

                                                        - To provide and improve our services, including facilitating transactions and providing customer support.
                                                        - To communicate with you, including sending important updates, newsletters, and marketing communications that may be of interest to you.
                                                        - To ensure the security of our platform, prevent fraud, and enforce our policies.

                                                        <br /><br />   4. Sharing of Information <br />

                                                        We do not sell or rent your personal data to third parties. However, we may share your information with trusted service providers who assist us in operating our website, conducting our business, or servicing you, provided they agree to keep your information confidential.

                                                        <br /><br />   5. Security Measures  <br />

                                                        We have implemented technical and organizational measures to protect your personal information against accidental loss, unauthorized access, use, alteration, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, so we cannot guarantee absolute security.

                                                        <br /><br />   6. Your Rights  <br />

                                                        You have certain rights regarding your personal data under applicable data protection laws, including the right to access, correct, or delete your information. You may also have the right to object to or restrict certain types of processing.

                                                        <br /> <br />  7. Changes to This Policy  <br />

                                                        We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website and updating the "Last Updated" date at the top of the policy.

                                                        <br />   <br />8. Contact Us  <br />

                                                        If you have any questions about this Privacy Policy or our data practices, please contact us at support@Adroxmarket.com.</p>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>);
    }
}
export default Privacy;