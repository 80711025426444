import React, { Component } from "react";

import img1 from "../assets/images/resource/img1.png";
import { Container } from "react-bootstrap"; 
import Animation, { fadeInLeft } from "../Util/Animation";
import { fadeInDown, fadeInUp } from "react-animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import pdf from "../assets/images/adroxwhitepaper.pdf"


class Whitepaper extends Component {
  render() {
    return (
      <div class="pb-90 sell-collect-section">
        <Container >
          <br />
          <br />
          <br />
          <br />
          <Animation className="row sell-bg"  variants={fadeInLeft}  delay={400}  > 
            <div class="col-md-6 col-sm-12 col-lg-6">
            <Animation className="sell title" variants={fadeInDown}  delay={400} >  
                <h1>ADROX Whitepaper</h1>
                <p class="nft-text">
                  The ADROX whitepaper is a comprehensive document that outlines
                  the technical foundation features, and vision of the Automated
                  Decentralized Resource Optimization Exchange Wallet. It
                  details how ADROX leverages blockchain technology smart
                  contracts and advanced algorithms to automate and optimize
                  digital asset management and exchanges.
                </p>
                <div class="sell-button">
                  <a href={pdf}>
                    Read WhitePaper <i ><FontAwesomeIcon icon={faArrowRight} /></i>
                  </a>
                </div>
              </Animation>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-6">
                <Animation className="sell-collect-thumb" variants={fadeInUp}  delay={400} >   
                <img alt="nft-img" src={img1} />
              </Animation>
            </div>
          </Animation>
        </Container>
      </div>
    );
  }
}
export default Whitepaper;
