import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Terms extends Component {

    render() {
        return (<>
            <div class="breadcome-area d-flex align-items-center">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-12">
                            <div class="breadcome-title">
                                <h2>Terms & Conditions</h2>
                                <p><Link to={'/'} >Home <span>Terms & Conditions</span></Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-section pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-details-box details">
                                        <div class="blog-box-inner">
                                            <div class="dreamit-blog-thumb">
                                                {/* <img src="assets/images/resource/blog-d.png" alt=""> */}
                                            </div>
                                            <div class="popular-blog-content">

                                                <div class="blog-title">
                                                    <h2><Link>Terms & Conditions of adroxmarket.com  </Link></h2>
                                                </div>
                                                <div class="blog-text white">

                                                    <p class="details-text">
                                                        Welcome to Adroxmarket.com. These Terms of Use ("Terms") govern your use of our website and services. By accessing or using Adrox Market, you agree to be bound by these Terms, constituting a legal agreement between you and Adrox Technologies LTD.

                                                        <br /><br />1. Description of Service  <br />

                                                        Adrox Market provides a digital platform for the presale of Adrox (ADX) coins. Our services enable users to participate in the presale, subject to achieving our presale goals.

                                                        <br />  <br />2. Disclaimer  <br />

                                                        The content on Adroxmarket.com is for general informational purposes only and does not constitute financial, investment, legal, or professional advice. All information regarding the ADX coin presale is subject to change. Adrox Market operates under Adrox Technologies LTD.

                                                        <br />  <br />3. Risk Acknowledgement  <br />

                                                        Investing in cryptocurrencies carries high risk. You acknowledge the volatile nature of ADX coins and that the success of the project depends on the presale's outcome. Adrox Market does not guarantee specific results or the project's successful launch.

                                                        <br /> <br /> 4. Eligibility and Participation  <br />

                                                        To participate in the presale, you must meet eligibility criteria, including age and compliance with local laws. You are responsible for ensuring legal participation in the presale in your jurisdiction.

                                                        <br /><br /> 5. Limitation of Liability  <br />

                                                        Adrox Market, its affiliates, and officers are not liable for any losses or damages arising from your use of the website or participation in the presale. You agree to indemnify Adrox Market against any claims, losses, or expenses resulting from your breach of these Terms.

                                                        <br />  <br />6. Intellectual Property <br />

                                                        All content on Adroxmarket.com, including text, graphics, logos, and images, is the property of Adrox Market or its licensors and is protected by copyright and intellectual property laws.

                                                        <br /> <br /> 7. Changes to the Terms  <br />

                                                        Adrox Market reserves the right to update these Terms at any time. We will notify you of significant changes, and your continued use of the website constitutes acceptance of the revised Terms.

                                                        <br />  <br />8. Governing Law  <br />

                                                        These Terms are governed by the laws of [Jurisdiction], excluding its conflict of law provisions.

                                                        <br />  <br />9. Contact Us <br />

                                                        If you have questions about these Terms, please contact us at Support@adroxmarket.com.



                                                    </p>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>);
    }
}
export default Terms;