import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cookie extends Component {

    render() {
        return (<>
            <div class="breadcome-area d-flex align-items-center">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-12">
                            <div class="breadcome-title">
                                <h2>Cookie Policy</h2>
                                <p><Link to={'/'} >Home <span>Cookie Policy</span></Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="blog-section pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-details-box details">
                                        <div class="blog-box-inner">
                                            <div class="dreamit-blog-thumb">
                                                {/* <img src="assets/images/resource/blog-d.png" alt=""/> */}
                                            </div>
                                            <div class="popular-blog-content">

                                                <div class="blog-title">
                                                    <h2><Link>Cookie Policy of adroxmarket.com  </Link></h2>
                                                </div>
                                                <div class="blog-text white">

                                                    <p class="details-text">
                                                        1.   Introduction  <br />

                                                        Adroxmarket.com ("we", "our", or "us") uses cookies to enhance your experience on our website. This Cookie Policy explains how and why we use cookies.

                                                        <br /> <br />	2.   What Are Cookies?   <br />

                                                        Cookies are small data files stored on your device that help us improve our site and your experience.

                                                        <br /> <br />	3.   Use of Cookies  <br />

                                                        We use cookies for the following purposes: <br />

                                                        -   Enhancing User Experience:   Cookies allow us to remember your preferences and settings, providing you with a more personalized experience. <br />

                                                        -   Analytics and Performance:   We utilize cookies to collect information about how visitors interact with our website. This helps us analyze traffic patterns and improve our website in order to tailor it to customer needs.

                                                        <br /> <br />	4.   Your Choices  <br />

                                                        You have the option to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. However, this may prevent you from taking full advantage of the website.

                                                        <br /> <br />5.   Changes to This Policy   <br />

                                                        We may update this Cookie Policy from time to time to reflect changes in our practices and legal requirements. We encourage you to review this policy periodically for any updates.

                                                        <br /> <br />	6.   Contact Us  <br />

                                                        If you have any questions about this Cookie Policy or our use of cookies, please contact us at Support@adroxmarket.com.

                                                        By continuing to use our website, you consent to the use of cookies as described in this policy.



                                                    </p>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>);
    }
}
export default Cookie;