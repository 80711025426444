import React, { Component } from "react";
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

export const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
};

export const fadeInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
};

export const fadeInDown = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
};

export const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
};
class Animation extends Component { 
    static propTypes = {
        className: PropTypes.string,
        variants: PropTypes.object.isRequired,
        initial: PropTypes.string,
        animate: PropTypes.string,
        delay: PropTypes.number,
        duration: PropTypes.number,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        className: '',
        initial: 'hidden',
        animate: 'visible',
        delay: 0.4,
        duration:0.5
    };
    render() {
        const {
            className,
            variants,
            initial,
            animate,
            delay,
            duration,
            children,
        } = this.props;
        return (
            <motion.div
                className={className}
                variants={variants}
                initial={initial}
                animate={animate}
                transition={{ delay: delay/1000, duration: duration/1000 }}
            >
                {children}
            </motion.div>
        );
    }
}
export default Animation;